



































import { ref, defineComponent } from '@nuxtjs/composition-api';
import { useUiHelpers, useImage } from '~/composables';
import { SfSection, SfCategoryCard } from '@storefront-ui/vue';

export default defineComponent({
  components: {
    SfSection,
    SfCategoryCard,
  },
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  setup() {
    const isHover = ref(false);
    const { getCatLink } = useUiHelpers();
    const { getMagentoImage } = useImage();

    return {
      isHover,
      getCatLink,
      getMagentoImage,
    };
  },
});
