



























import { defineComponent } from '@nuxtjs/composition-api';
import LinkWithArrow from '~/components/General/LinkWithArrow.vue';

export default defineComponent({
  components: {
    LinkWithArrow,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({
        loading: 'lazy',
        format: 'webp',
        width: 1200,
        height: 600,
        placeholder: true,
      }),
    },
  },
});
