<template>
  <div class="sf-carousel">
    <div ref="controls" class="sf-carousel__controls">
      <slot name="prev" v-bind="{ go: () => go('prev') }">
        <SfButton
          class="sf-button--pure"
          aria-label="previous"
          @click="go('prev')"
        >
          <SvgImage
            icon="arrow_left_short"
            label="previous"
            width="8"
            height="18"
          />
        </SfButton>
      </slot>
      <slot name="next" v-bind="{ go: () => go('next') }">
        <SfButton
          class="sf-button--pure"
          aria-label="next"
          @click="go('next')"
        >
          <SvgImage
            icon="arrow_right_short"
            label="next"
            width="8"
            height="18"
          />
        </SfButton>
      </slot>
    </div>
    <div class="sf-carousel__wrapper">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides sf-carousel__slides">
            <slot />
          </ul>
        </div>
      </div>
    </div>
    <div v-if="numberOfPages > 1 && !noBullets">
      <slot name="bullets" v-bind="{ numberOfPages, page, go }">
        <SfBullets
          :total="numberOfPages"
          :current="page"
          @click="go($event)"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import SfCarouselItem from '@storefront-ui/vue/src/components/organisms/SfCarousel/_internal/SfCarouselItem.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { SfButton, SfBullets } from '@storefront-ui/vue';
import Glide from '@glidejs/glide';

Vue.component('SfCarouselItem', SfCarouselItem);

export default {
  components: {
    SfButton,
    SfBullets,
    SvgImage,
  },
  props: {
    noBullets: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      perView: 4,
      glide: null,
      defaultSettings: {
        type: 'carousel',
        rewind: true,
        perView: 4,
        gap: 0,
        slidePerPage: true,
        breakpoints: {
          1023: {
            perView: 2,
            peek: {
              before: 0,
              after: 50,
            },
          },
        },
      },
    };
  },
  computed: {
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints,
      };
    },
    numberOfPages() {
      return Math.ceil((this.$slots.default || []).filter((slot) => slot.tag).length / this.perView);
    },
    page() {
      if (this.glide) {
        return Math.ceil(this.glide.index / this.perView);
      }
      return 0;
    },
  },
  watch: {
    items() { // fix for useFetch
      this.setupGlide();
    },
  },
  mounted() {
    this.setupGlide();
  },
  methods: {
    setupGlide() {
      this.$nextTick(() => {
        if (!this.$slots.default) return;
        const glide = new Glide(this.$refs.glide, this.mergedOptions);
        const size = (this.$slots.default || []).filter((slot) => slot.tag).length;
        glide.mount();
        glide.on('run.before', (move) => {
          const { slidePerPage, rewind, type } = this.mergedOptions;
          if (!slidePerPage) return;
          const { perView } = glide.settings;
          if (!perView > 1) return;
          const { direction } = move;
          let page;
          let newIndex;
          switch (direction) {
            case '>':
            case '<':
              page = Math.ceil(glide.index / perView);
              newIndex = page * perView + (direction === '>' ? perView : -perView);
              if (newIndex >= size) {
                if (type === 'slider' && !rewind) {
                  newIndex = glide.index;
                } else {
                  newIndex = 0;
                }
              } else if (newIndex < 0 || newIndex + perView > size) {
                if (type === 'slider' && !rewind) {
                  newIndex = glide.index;
                } else {
                  newIndex = size - perView;
                }
              }
              move.direction = '=';
              move.steps = newIndex;
          }
        });
        this.glide = glide;
        this.perView = glide._c.Breakpoints.match(glide.settings.breakpoints).perView;
        if (size <= this.perView) {
          glide.settings.perView = size;
          glide.settings.rewind = false;
          this.$refs.controls.style.display = 'none';
        }
      });
    },
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case 'prev':
          this.glide.go('<');
          break;
        case 'next':
          this.glide.go('>');
          break;
        default:
          const num = (this.$slots.default || []).filter((slot) => slot.tag).length < (direct + 1) * this.perView
            ? (this.$slots.default || []).filter((slot) => slot.tag).length - this.perView
            : direct * this.perView;
          this.glide.go(`=${num}`);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfCarousel.scss";

.sf-bullets {
  --bullet-margin: 4px;
  --bullet-width: 16px;
  --bullet-height: 5px;
  --bullet-border-radius: 3px;
  --bullet-box-shadow: none;
  --bullet-background: var(--c-additional-2);

  transform: translate3d(0, 0, 0);
  margin-top: 16px;
  margin-left: calc(-1 * var(--bullet-margin));

  .sf-bullet:hover, .sf-bullet.is-active {
    transform: none;
    background: var(--c-primary);
  }
}

.sf-carousel {
  &__controls {
    height: 100%;
    .sf-button {
      --button-padding: 8px;
    }
  }
}
</style>
