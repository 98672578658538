import { UseContextReturn } from "~/types/core";
import loadYoastSeoPageMetaWithLdJsonProduct from '~/modules/yoastSeo/queries/loadYoastSeoPageMetaWithLdJsonProduct.gql';

export const loadProductMetaWithLdJsonCommand = {
  execute: async (context: UseContextReturn, entityType: string, field: string, value: string) => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({
      query: loadYoastSeoPageMetaWithLdJsonProduct,
      queryVariables: {
        entityType,
        field,
        value,
      }
    })

    if (errors && errors.length > 0) {
      console.error('YoastSeo loadProductMetaWithLdJsonCommand error', errors);
    }

    if (!data) {
      return null;
    }
    return {
      meta: (data as any).yoast_seo_page_meta,
      ldJson: (data as any).yoast_seo_ld_json_product,
    };
  }
}
