






























import {
  defineComponent, useContext, useFetch, ref, computed,
} from '@nuxtjs/composition-api';
import { SfSection } from '@storefront-ui/vue';
import Carousel from '~/components/Home/Carousel.vue';
import Banner from '~/components/Home/Banner.vue';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  components: {
    SfSection,
    Carousel,
    Banner,
  },
  setup() {
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const { getCatLink } = useUiHelpers();
    const { result, search }: any = useCategorySearch();
    const items = ref([]);
    const responsiveItems = computed(() => isDesktop ? items.value : items.value.slice(1));
    const settings = {
      type: 'slider',
      autoplay: 5000,
      perView: 1,
      breakpoints: {
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
    };

    useFetch(async () => {
      await search({ filters: { ids: { eq: '212' } } });
      const collections = (result.value || []).map((collection) => ({
        title: 'Our collections',
        description: collection.description || 'We have collected for you collections that have their own unique style and functionality. The advantage of our collections is natural wood, which is used in production. Get to know each collection closer and choose what you like.',
        buttonText: 'All collections',
        link: getCatLink(collection),
        image: collection.image || '/home/collections.png',
        nuxtImgConfig: {
          loading: 'lazy',
          format: 'webp',
          width: isDesktop ? 1200 : 600,
          height: isDesktop ? 630 : 600,
        },
      }));

      await search({ filters: { parent_id: { eq: '212' } } });
      const children = (result.value || []).map((collection) => ({
        title: collection.name,
        description: collection.description || 'We have collected for you collections that have their own unique style and functionality. The advantage of our collections is natural wood, which is used in production. Get to know each collection closer and choose what you like.',
        buttonText: 'Go to collection',
        link: getCatLink(collection),
        image: collection.image || '/home/collections.png',
        nuxtImgConfig: {
          loading: 'lazy',
          format: 'webp',
          width: isDesktop ? 1200 : 600,
          height: isDesktop ? 630 : 600,
        },
      }));

      items.value = [...collections, ...children];
    });

    return {
      settings,
      items,
      responsiveItems,
    };
  },
});
