import { UseYoastSeoInterface } from '~/modules/yoastSeo/composables/useYoastSeo/useYoastSeo';
import { HomeMetaWithLdJson, MetaTag, ProductMetaWithLdJson } from '~/modules/yoastSeo/composables/useYoastSeo/useYoastSeo';
import { loadMetaCommand } from '~/modules/yoastSeo/composables/useYoastSeo/loadMetaCommand';
import { loadHomeMetaWithLdJsonCommand } from '~/modules/yoastSeo/composables/useYoastSeo/loadHomeMetaWithLdJsonCommand';
import { loadProductMetaWithLdJsonCommand } from '~/modules/yoastSeo/composables/useYoastSeo/loadProductMetaWithLdJsonCommand';
import { Logger } from '~/helpers/logger';
import { useContext } from "@nuxtjs/composition-api";

export function useYoastSeo(): UseYoastSeoInterface {
  const context = useContext();

  const loadMeta = async (entityType: string, field: string, value: string): Promise<MetaTag[]> => {
    try {
      let tags = await loadMetaCommand.execute(context, entityType, field, value);
      if (Array.isArray(tags)) {
        tags = tags.map(tag => ({
          hid: tag.name,
          ...tag
        }));
      }
      return tags;
    } catch (err) {
      Logger.error('useYoastSeo/loadMeta', err);
      return null;
    }
  }

  const loadHomeMetaWithLdJson = async (entityType: string, field: string, value: string): Promise<HomeMetaWithLdJson> => {
    try {
      let { meta: tags, organizationLdJson, websiteLdJson } = await loadHomeMetaWithLdJsonCommand.execute(context, entityType, field, value);
      if (Array.isArray(tags)) {
        tags = tags.map(tag => ({
          hid: tag.name,
          ...tag
        }));
      }
      return { meta: tags, organizationLdJson, websiteLdJson };
    } catch (err) {
      Logger.error('useYoastSeo/loadHomeMetaWithLdJson', err);
      return null;
    }
  }

  const loadProductMetaWithLdJson = async (entityType: string, field: string, value: string): Promise<ProductMetaWithLdJson> => {
    try {
      let { meta: tags, ldJson } = await loadProductMetaWithLdJsonCommand.execute(context, entityType, field, value);
      if (Array.isArray(tags)) {
        tags = tags.map(tag => ({
          hid: tag.name,
          ...tag
        }));
      }
      return { meta: tags, ldJson };
    } catch (err) {
      Logger.error('useYoastSeo/loadProductMetaWithLdJson', err);
      return null;
    }
  }

  return {
    loadMeta,
    loadHomeMetaWithLdJson,
    loadProductMetaWithLdJson,
  }
}
