











































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ImageModifiers } from '@nuxt/image';
import { SfImage } from '@storefront-ui/vue';
import LinkWithArrow from '~/components/General/LinkWithArrow.vue';

export default defineComponent({
  components: {
    SfImage,
    LinkWithArrow,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: [Number, String],
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    imageTag: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
  },
});
