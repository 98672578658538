export default `
  query YoastSeoPageMetaWithLdJsonOrganizationWebsite($entityType: String!, $field: String!, $value: String!) {
    yoast_seo_page_meta(entityType: $entityType, field: $field, value: $value) {
      name
      content
    }
    yoast_seo_ld_json_organization
    yoast_seo_ld_json_website
  }
`;
