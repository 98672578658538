







import {
  ref, defineComponent, useFetch, useContext,
} from '@nuxtjs/composition-api';
import type { CategoryTree } from '~/modules/GraphQL/types';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import CategoriesGrid from '~/components/Home/CategoriesGrid.vue';
import CategoriesSlider from '~/components/Home/CategoriesSlider.vue';
import { useConfig } from '~/composables';

export default defineComponent({
  components: {
    CategoriesGrid,
    CategoriesSlider,
  },
  setup() {
    const { categories, load: categoriesListLoad } = useCategory();
    const categoryTree = ref<CategoryTree[]>([]);
    const { config } = useConfig();
    const { app } = useContext();

    const baseUrl = config.value.base_url || app.$vsf.$magento.config.magentoBaseUrl;
    const order = ['Bunk Beds', 'Loft Beds', 'Bed Frames', 'Futons', 'Case Goods', 'Bean Bags', 'Accessories', 'Mattresses'];
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 8 });
      categoryTree.value = categories.value?.[0]?.children
        .map((cat) => ({ ...cat, additional_home_image: `${baseUrl}${cat['additional_home_image']}` }))
        .filter((cat) => cat.include_in_menu)
        .slice(0, 8)
        .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
    });

    return {
      categoryTree,
    };
  },
});
