
























import {useConfig, useUiState} from '~/composables';
import { defineComponent } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import LinkWithArrow from '~/components/General/LinkWithArrow.vue';

export default defineComponent({
  components: {
    SvgImage,
    LinkWithArrow,
  },
  setup() {
    const { toggleShippingModal } = useUiState();
    const { config } = useConfig();

    //@ts-ignore
    const shippingDescription = config.value.home_page_shipping_free_shipping;

    const shipping = {
      icon: 'shipping',
      title: 'Free Shipping',
      description: shippingDescription,
      buttonText: 'Find out more',
    };

    return {
      shipping,
      toggleShippingModal,
    };
  },
});
