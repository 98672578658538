

























import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import Carousel from '~/components/Home/Carousel.vue';
import { useConfig } from '~/composables';

export default defineComponent({
  components: {
    SfImage,
    Carousel,
  },
  setup() {
    const { config } = useConfig();
    // @ts-ignore
    const solidWoodText = config.value.home_page_features_solid_wood;
    // @ts-ignore
    const bunkBedsText = config.value.home_page_features_bunk_beds;
    // @ts-ignore
    const shippingText = config.value.home_page_features_shipping;
    // @ts-ignore
    const inStockText = config.value.home_page_features_in_stock;

    const items = [{
      image: '/home/wood.png',
      title: 'Solid wood',
      description: solidWoodText,
    }, {
      image: '/home/berth.png',
      title: 'Bunk beds',
      description: bunkBedsText,
    }, {
      image: '/home/truck.png',
      title: 'Shipping',
      description: shippingText,
    }, {
      image: '/home/warehouse.png',
      title: 'In stock',
      description: inStockText,
    }];

    const settings = {
      type: 'slider',
      rewind: true,
      perView: 4,
      slidePerPage: true,
      gap: 50,
      breakpoints: {
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
    };

    return {
      items,
      settings,
    };
  },
});
