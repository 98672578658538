import { UseContextReturn } from "~/types/core";
import loadYoastSeoPageMetaWithLdJsonOrganizationWebsite from '~/modules/yoastSeo/queries/loadYoastSeoPageMetaWithLdJsonOrganizationWebsite.gql';

export const loadHomeMetaWithLdJsonCommand = {
  execute: async (context: UseContextReturn, entityType: string, field: string, value: string) => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({
      query: loadYoastSeoPageMetaWithLdJsonOrganizationWebsite,
      queryVariables: {
        entityType,
        field,
        value,
      }
    })

    if (errors && errors.length > 0) {
      console.error('YoastSeo loadHomeMetaWIthLdJsonCommand error', errors);
    }

    if (!data) {
      return null;
    }
    return {
      meta: (data as any).yoast_seo_page_meta,
      organizationLdJson: (data as any).yoast_seo_ld_json_organization,
      websiteLdJson: (data as any).yoast_seo_ld_json_website,
    };
  }
}
