








































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfSection, SfCategoryCard } from '@storefront-ui/vue';
import Carousel from '~/components/Home/Carousel.vue';

export default defineComponent({
  components: {
    SfSection,
    SfCategoryCard,
    Carousel,
  },
  setup() {
    const isHover = ref(false);
    const items = [{
      image: '/home/blog-1.png',
      title: 'bunk beds',
      description: 'Learn about:<br><br>• sizes<br>• heights<br>• weight capacity<br>• climbing styles n options',
    }, {
      image: '/home/blog-2.png',
      title: 'loft beds',
      description: 'Learn about:<br><br>• sizes<br>• heights<br>• weight capacity<br>• climbing styles n options',
    }, {
      image: '/home/blog-3.png',
      title: 'bed frames',
      description: 'Learn about:<br><br>• sizes<br>• heights<br>• weight capacity<br>• climbing styles n options',
    }, {
      image: '/home/blog-4.png',
      title: 'mattresses',
      description: 'Learn about:<br><br>• sizes<br>• heights<br>• weight capacity<br>• climbing styles n options',
    }];

    const settings = {
      type: 'slider',
      rewind: true,
      perView: 4,
      slidePerPage: true,
      gap: 20,
      breakpoints: {
        1023: {
          perView: 1,
          gap: 10,
          peek: {
            before: 0,
            after: 35,
          },
        },
      },
    };

    return {
      isHover,
      items,
      settings,
    };
  },
});
