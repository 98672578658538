import { UseContextReturn } from "~/types/core";
import loadYoastSeoPageMeta from '~/modules/yoastSeo/queries/loadYoastSeoPageMeta.gql';

export const loadMetaCommand = {
  execute: async (context: UseContextReturn, entityType: string, field: string, value: string) => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({
      query: loadYoastSeoPageMeta,
      queryVariables: {
        entityType,
        field,
        value,
      }
    })

    if (errors && errors.length > 0) {
      console.error('YoastSeo loadMetaCommand error', errors);
    }

    return (data as any)?.yoast_seo_page_meta ?? null;
  }
}
