




















































import {
  defineComponent, ref, useContext, onMounted, useFetch,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { CmsPage } from '~/modules/GraphQL/types';
import MainBanner from '~/components/Home/MainBanner.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent } from '~/composables';
import Features from '~/components/Home/Features.vue';
import Collections from '~/components/Home/Collections.vue';
import Shipping from '~/components/Home/Shipping.vue';
import Categories from '~/components/Home/Categories.vue';
import BlogSlider from '~/components/Home/BlogSlider.vue';
import Banner from '~/components/Home/Banner.vue';
import { useYoastSeo } from '~/modules/yoastSeo/composables/useYoastSeo';

export default defineComponent({
  name: 'HomePage',
  components: {
    LazyHydrate,
    MainBanner,
    Features,
    Collections,
    Shipping,
    Categories,
    BlogSlider,
    Banner,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { loadHomeMetaWithLdJson } = useYoastSeo();
    const { app } = useContext();
    const { isDesktop } = app.$device;

    const page = ref<CmsPage | null>(null);
    const hero = ref({
      title: {
        desktop: 'Welcome to Bunk Beds Canada',
        mobile: 'Welcome to<br>Bunk Beds Canada',
      },
      subtitle: {
        desktop: 'Bunk Beds Canada are a family based company from the beautiful city of Vancouver located<br>in the supernatural province of BC in the great country of Canada.<br>We specialize in solid wood, durable bunk beds and related products.',
        mobile: 'We specialize in solid wood, durable<br>bunk beds and related products.',
      },
      buttonText: 'About Us',
      link: '/about-us',
      imageSrc: '/home/banner-1.jpeg',
      nuxtImgConfig: {
        loading: 'lazy',
        format: 'webp',
        width: isDesktop ? 1824 : 912,
        height: isDesktop ? 674 : 337,
      },
    });
    const banners = [{
      title: 'Shop Made in Canada',
      description: 'Products made in Canada are made from solid southern yellow pine. Premium wood without knots is your guarantee of safety and quality.',
      buttonText: 'Go to directory',
      link: '/made-in-canada',
      image: '/home/banner-2.jpeg',
      nuxtImgConfig: {
        loading: 'lazy',
        format: 'webp',
        width: isDesktop ? 1200 : 180,
        height: isDesktop ? 600 : 268,
      },
    }, {
      title: 'Quick Ship',
      description: 'We have selected for you the goods that are delivered within 1-2 weeks',
      buttonText: 'Go to directory',
      link: '/quick-ship',
      image: '/home/banner-3.png',
      nuxtImgConfig: {
        loading: 'lazy',
        format: 'webp',
        width: isDesktop ? 1200 : 180,
        height: isDesktop ? 600 : 268,
      },
      class: 'banner--reverse',
    }];

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    const customMeta = ref(null);
    const organizationLdJson = ref(null);
    const websiteLdJson = ref(null);
    useFetch(async () => {
      const result = await loadHomeMetaWithLdJson('cms_page', 'identifier', 'home');
      customMeta.value = result.meta;
      organizationLdJson.value = result.organizationLdJson;
      websiteLdJson.value = result.websiteLdJson;
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    return {
      hero,
      banners,
      page,
      customMeta,
      organizationLdJson,
      websiteLdJson,
    };
  },
  head() {
    const metaInfo = getMetaInfo(this.page);
    if (metaInfo && this.customMeta) {
      metaInfo.meta.push(...this.customMeta);
    }
    return metaInfo;
  },
});
