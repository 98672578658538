


































import { defineComponent } from '@nuxtjs/composition-api';
import { SfSection, SfCategoryCard } from '@storefront-ui/vue';
import { useUiHelpers } from '~/composables';
import Carousel from '~/components/Home/Carousel.vue';

export default defineComponent({
  components: {
    SfSection,
    SfCategoryCard,
    Carousel,
  },
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();

    const settings = {
      type: 'slider',
      rewind: true,
      perView: 4,
      slidePerPage: true,
      gap: 20,
      breakpoints: {
        1023: {
          perView: 1,
          gap: 10,
          peek: {
            before: 0,
            after: 35,
          },
        },
      },
    };

    return {
      getCatLink,
      settings,
    };
  },
});
